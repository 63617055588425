import Vue from 'vue';
import ls from '@/components/helpers/local-storage';
import axios from 'axios';

const Config = require('~/config/default.env').default;

const helpers = Vue.mixin({
  methods: {
    async cancelOrder(logout = false, url = '', global_loading = true, is_order_created = null) {
      this.$store.commit('SET_GLOBAL_LOADING', global_loading);
      this.$store.commit('SET_IS_ORDER_CREATED', null);

      if ((url !== '/vmcola/home') & !is_order_created) {
        await this.removeProducts();
      }

      Promise.all([
        ls.remove('cart'),
        ls.remove('latest_cart_id'),
        this.$store.commit('SET_CART_ID', null),
        this.$store.commit('footer_cart', []),
        this.$store.commit('cart_qty', 0),
        this.$store.commit('SET_ALREADY_SEND_CART', false),
        this.$store.commit('SET_SELECTED_POINT', 0),
      ]);

      this.$store.state.vmCola.footer_cart = [];
      this.$store.state.vmCola.cart_qty = 0;

      let path = url || '/vmcola/products';

      if ((await Cookies.get('vending_machine_device')) == 'true') {
        path = '/vending-machine/home';
      }

      if (logout) {
        this.$store.dispatch('destroyQrSession', {
          router: this.$router,
          redirectUrl: path,
        });
      } else {
        if (url) {
          this.$router.push(url);
        }
      }
    },

    async removeProducts() {
      let cart_id = this.$store.state.QRcode.latest_cart_id;

      if (!cart_id) {
        cart_id = await ls.get('latest_cart_id');
      }

      if (!cart_id) {
        return;
      }

      const carts = this.$store.state.vmCola.footer_cart;

      const promises = [];

      const [loc, is_guest] = await Promise.all([Cookies.get('store_location_id'), ls.get('is_guest'), ,]);

      const headers = {
        'SOC-PLATFORM': 'vending-machine',
        store_id: loc,
      };

      const authToken = this.$store.state.QRcode.authToken;
      if (authToken) {
        headers['Authorization'] = `Bearer ${authToken}`;
      } else {
        const token = await Cookies.get('token');
        headers['Authorization'] = `Bearer ${token}`;
      }

      if (is_guest) {
        headers['is-guest'] = true;
        headers['guest-checkout'] = true;
      }

      for (let i = 0; i < (carts || []).length; i++) {
        const cart = carts[i];
        const payload = {
          product: {
            id: cart.raw.id,
            combination_id: cart.raw.combination_id,
            quantity: cart.qty || 1,
          },
          action: 'delete',
        };

        if (cart_id) {
          payload['cart_id'] = cart_id;
        }

        promises.push(axios.post(`${Config.MS_CART_API_URL}/user/me/carts`, payload, { headers: headers }));
      }

      if (promises.length) {
        await Promise.all(promises);
      }
    },
  },
});

export default helpers;
